import _textEncodingPolyfill from "text-encoding-polyfill";
import _string_decoder from "string_decoder";
var exports = {};
_textEncodingPolyfill;
var StringDecoder = _string_decoder.StringDecoder;

function defaultDecoder(data) {
  var decoder = new StringDecoder();
  var out = decoder.write(data) + decoder.end();
  return out.replace(/\0/g, "").trim();
}

exports = createDecoder;
var regex = /^(?:ANSI\s)?(\d+)$/m;

function createDecoder(encoding, second) {
  if (!encoding) {
    return defaultDecoder;
  }

  try {
    new TextDecoder(encoding.trim());
  } catch (e) {
    var match = regex.exec(encoding);

    if (match && !second) {
      return createDecoder("windows-" + match[1], true);
    } else {
      return defaultDecoder;
    }
  }

  return browserDecoder;

  function browserDecoder(buffer) {
    var decoder = new TextDecoder(encoding);
    var out = decoder.decode(buffer, {
      stream: true
    }) + decoder.decode();
    return out.replace(/\0/g, "").trim();
  }
}

export default exports;